// Poke colors
$red: hsl(3, 95%, 45%);
$orange: hsl(30, 85%, 60%);
$green: hsl(85, 60%, 55%);
$yellow: hsl(50, 100%, 70%);
$purple: hsl(315, 35%, 35%);

$primary: $purple;
$danger: $red;
$success: $green;
$warning: $yellow;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

$utilities: map-merge(
    (
        'max-width2': (
            property: max-width,
            class: max-w,
            values:
                map-merge(
                    $grid-breakpoints,
                    (
                        xs: 480px,
                    )
                ),
        ),
    ),
    $utilities
);

$body-color: #ffffff;
$body-bg: hsl(120deg, 0%, 15%);

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/alert';

@import '~bootstrap/scss/helpers';

@import '~bootstrap/scss/utilities/api';
